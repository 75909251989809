import React, { useEffect } from "react";
import './OfficeAndMap.css';
import { useMemo, useState } from "react";
// import { GoogleMap, useLoadScript, MarkerF, InfoWindow, } from "@react-google-maps/api";

export interface IOfficeAndMapProps { }

export const OfficeAndMap: React.FC<IOfficeAndMapProps> = (props) => {

    //if mobile device
    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = 769;

    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
    }, []);

    let mapWidth = "810";
    if(width < breakpoint){
        mapWidth = "400";
    }

    return (
        <div className="mainContainer">
        <div className="container">
            <div className="content">
                <div className="infoTextContainer">
                    <div className="infoText">
                        <h3>Vårt kontor</h3>
                        <p>Västberga allé 9</p>
                        <p><strong>Kontakt</strong></p>
                        <p>info@kellstransport.se</p>
                        <p><strong>Öppettider</strong></p>
                        <p> Mån - Fre       kl 10-17</p>
                    </div>
                </div>
                <div className="mapContainer">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2037.0555945153903!2d18.011286566888455!3d59.29862870598571!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f77afe21dfa7f%3A0xd772d36ac5ca942e!2zVsOkc3RiZXJnYSBBbGzDqSA5LCAxMjYgMzAgSMOkZ2Vyc3Rlbg!5e0!3m2!1sen!2sse!4v1671395970899!5m2!1sen!2sse" width={mapWidth} height="440" className="map" ></iframe>
                </div>
            </div>
        </div>
        </div>
    );
}