import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CtaSection } from "../components/CtaSection";
import SavingCalcImg from "../assets/images/savingCalcHomeProd.png";
import SavingCalcImgMobile from "../assets/images/savingCalcHomeProdMobile.png";
import ruttplanImg from "../assets/images/ruttplaneringSectionHomeProd.png";
import leveranserSectionImg from "../assets/images/leveranserSectionHomeProd.png";
import verksamhetSectionImg from "../assets/images/verksamhetSectionHomeProd.png";
import leafImg from "../assets/images/leafIcon.png";
import truckImg from "../assets/images/truckIcon.png";
import wheelImg from "../assets/images/wheelImg.png";
import bgHeroMovie from "../assets/videos/bgVideoHome.mp4";
import BrodOchSaltLogo from "../assets/images/BrodOchSaltLogo.png";
import WebFleetTestimonial from "../assets/images/WebFleetTestimonial.png";
import { ThreeIconSection } from "../components/ThreeIconSection";
import { InfoSectionWithTextAndImg } from "../components/InfoSectionWithTextAndImg";
import styles from "./HomePage.module.css";
import '../style.css';
import { HeaderBgVideo } from "../components/HeaderBgVideo";
import { TestimonialSlider } from "../components/TestimonialSlider";

export interface IHomePageProps { }

let headertitle = "Det lilla transportbolaget \nmed det stora tänket.";
let headerText = "Sänk era transportkostnader och CO2-utsläpp utan att kompromissa med kvaliteten.";

let icon1 = leafImg
let icon2 = truckImg
let icon3 = wheelImg
let titelIcon1 = "Öka miljömedvetenheten"
let titleIcon2 = "Leverera i tid"
let titleIcon3 = "Nya arbetssätt"
let textIcon1 = "Ruttplanering minskar inte bara era transportkostnader utan det bidrar till minskade CO2-utsläpp."
let textIcon2 = "Svårt att hinna med era kommande leveranser? Vi hjälper med era leveranser så ni kan bibehålla er kundnöjdhet."
let textIcon3 = "Fokusera på er kärnverksamhet och låt oss underlätta er resa mot digitaliseringen och mer effektiva arbetsdagar."


export const HomePage: React.FC<IHomePageProps> = (props) => {
  const navigate = useNavigate();
  const handleClickDemo = () => {
    navigate('/boka-demo')
  };
  const handleClick2 = () => {
    navigate('/kalkylator')
  };

  const [width, setWidth] = useState(window.innerWidth);
  const breakpointMobile = 769;

  useEffect(() => {
      window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);
  let ctaBgImg = SavingCalcImg;
  if (width < breakpointMobile) {
    ctaBgImg = SavingCalcImgMobile;
  }


  return (
    <div>
      <HeaderBgVideo
        video={bgHeroMovie}
        headertitle={headertitle}
        headerText={headerText}
        btnTitle="Boka demo"
        btnOnClick={handleClickDemo}
      />
      <ThreeIconSection
        titelSection={"Varför samarbeta med KELLS?"}
        textSection={"Vi erbjuder tjänster som hjälper er att minska era transportkostnader och CO2-utsläpp utan att kompromissa med kvaliteten. Vi har hjälp med ruttplanering, avlastning och digitalisering av er verksamhet."}
        icon1={icon1}
        icon2={icon2}
        icon3={icon3}
        titelIcon1={titelIcon1}
        textIcon1={textIcon1}
        titleIcon2={titleIcon2}
        textIcon2={textIcon2}
        titleIcon3={titleIcon3}
        textIcon3={textIcon3}
      />
      <div className="infoSectionsHome">
        <InfoSectionWithTextAndImg
          smallTitle={"Ruttplanering"}
          title={"Effektivisera era transporter och maximera era resurser med hjälp av ruttplanering"}
          text={"Klimatomställningen och digitaliseringen ställer höga krav på transportsektorn. Ruttplaneringen är ett verktyg som ökar medvetenheten om det dagliga transportbehovet, resursanvändningen, kostnadsbesparingar samt underlättar omställningen till mer klimatsamarta alternativ. Låt oss hjälpa er att planera och effektivera era dagliga transporter på bästa möjliga sätt. "}
          img={ruttplanImg}
          align={"left"}
        />
        <InfoSectionWithTextAndImg
          smallTitle={"Leveranser"}
          title={"Behöver ni hjälp med transporter? Hör er till oss för en offert "}
          text={"Ni kanske är i en expansionsfas och behöver utvidga fordonsflottan en aning? Eller behöver hjälp med enstaka leveranser? Vi kan hjälpa er att leverera allt från kylvaror till tunga gods. Vårt planeringsverktyg ser alltid till att transporten utförs klimatsmart och kostnadseffektivt. Kontakta oss så kan vi tillsammans hitta en skräddarsydd lösning för just er verksamhet."}
          img={leveranserSectionImg}
          align={"right"}
        />
        <InfoSectionWithTextAndImg
          smallTitle={"Verksamhetsutvärdering"}
          title={"Dags att kliva in i digitaliseringen? Vi underlättar resan åt er"}
          text={"Varför ändra ett vinnande koncept? Digitalisering innebär oftast en omställning vilket gör att det sällan omfamnas. Datainsamling via telematik är ett av många verktyg som kan användas för att utveckla verksamheten. Vi hjälper våra kunder med dataanalys och implementering av nya arbetssätt."}
          img={verksamhetSectionImg}
          align={"left"}
        />
      </div>
      <div className="CtaSectionWithBg">
        <CtaSection
          title={"Hur mycket kan vi spara?"}
          text={"Måste minskade utsläppa belasta plånboken? Använd vår räknesnurra för att se hur mycket ruttplanering sänker er kostnader och utsläpp."}
          btnTitle={"Räknesnurra"}
          btnOnClick={handleClick2}
          // bgImage={CalcPic}
          bgImage={ctaBgImg}
        />
      </div>
      <div>
      <TestimonialSlider
        headerTitle="Vad säger våra kunder?"
        testimonials={
          [
            {
              name: "Håkan Karlsson",
              text: '" Sättet som KELLS Transport utvecklar sin lösning i tät dialog med kunderna ger ett komplett verksamhetsstöd med en helt unik optimering. Det leder inte bara till klart bättre resursnyttjande och lönsamhet utan även ett rejält klliv mot mer hållbara transporter! "',
              workTitle: "Nordisk Systemingenjör, Webfleet",
              img: WebFleetTestimonial
            },
            {
              name: "Amanda B.",
              text: '"KELLS har sedan start varit engagerade, flexibla och lyhörda. De har levererat hög kompetens och vassa lösningar för hur vår logistik ständigt kan förbättras."',
              workTitle: "Order- och fakturahantering, Bröd&Salt",
              img: BrodOchSaltLogo
            }
          ]} />
      </div>
      <div>
        <CtaSection
          title={"Fokusera på er kärnverksamhet"}
          text={"Hör av er till oss så kan vi berätta lite mer om hur ni kan öka effektiviteten och få en mer strömlinjeformad logistikkedja."}
          btnTitle={"BOKA EN DEMO"}
          btnOnClick={handleClickDemo}
        // bgImage={SavingCalcImg}
        />
      </div>
    </div>

  );
};