import React, { useEffect, useState } from "react";
import { InfoSectionWithTextAndImg } from "../components/InfoSectionWithTextAndImg";
import picOne from "../assets/images/omOssPageOne.png";
import picTwo from "../assets/images/omOss2.png";
import mattiasPic from "../assets/images/mattias2.png";
import weebfleetImg from "../assets/images/webfleet.png";
import BosImg from "../assets/images/BosImg.png";
import ptvGroupImg from "../assets/images/ptvGroup.png";
import budwheelsImg from "../assets/images/budwheels.png";
import '../style.css';
import './OmOssPage.css';
import { SammarbetspartnerSection } from "../components/SammarbetspartnerSection";
import { useNavigate } from "react-router-dom";
import { CtaSection } from "../components/CtaSection";
import SavingCalcImg from "../assets/images/SavingCalcOmOssPageProd.png";
import SavingCalcImgMob from "../assets/images/SavingCalcOmOssPageProdMobile.png";

export interface IOmOssPageProps { }

export const OmOssPage: React.FC<IOmOssPageProps> = (props) => {
  const navigate = useNavigate();
  const handleClickDemo = () => {
    navigate('/boka-demo')
  };
  const handleClick2 = () => {
    navigate('/kalkylator')
  };


  const [width, setWidth] = useState(window.innerWidth);
  const breakpointMobile = 769;

  useEffect(() => {
      window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);
  let ctaBgImg = SavingCalcImg;
  if (width < breakpointMobile) {
    ctaBgImg = SavingCalcImgMob;
  }

  return (
    <div>
      <div className="infoSectionsOmOss">
        <InfoSectionWithTextAndImg
          smallTitle={"Om oss"}
          title={"Vi arbetar kontinuerligt med kunskapsinhämtning och försöker hitta nya lösningar och integrationer med olika system."}
          text={"Detta för att steget in till molnet och uppkopplade transportfordon inte ska upplevas som en totalomställning för våra kunder. Målet är att alltid hitta skräddarsydda lösningar då behoven är branschspecifika. Vi bakom bolaget har mer än 15-årserfarenhet från produktutveckling inom fordonsbranschen, det är också där passionen för ständig förbättring växt fram. Ständig förbättring och effektivisering är ett måste om våra globala klimatmål ska uppnås inom överskådlig framtid."}
          img={picOne}
          align={"right"}
        />
        <InfoSectionWithTextAndImg
          smallTitle={"Vår vision"}
          title={"KELLS Transport bildades 2020 med målet att digitalisera och utveckla transportsektorn."}
          text={"Vår affärsidé går ut på att utnyttja och använda befintlig teknik tillsammans med egna lösningar för att underlätta och effektivisera logistikkedjan. Våra tankar och förslag applicerades ganska omgående på vår första kund Bröd&Salt. Vi visade snabbt att det var möjligt att minska transportkostnaderna med mer än 20%. Bröd&Salt har sedan samarbetet med oss nästan tredubblat antalet butiker i kedjan utan att kompromissa med transportkvaliteten. Ruttplanering leder inte enbart till ökad lönsamhet och minskade CO2-utsläpp utan ger även en bättre överblick av hela logistikkedjan. Kontinuerlig datainsamling och uppföljning ger möjlighet till djupare analyser i hållbarhet-och utvecklingssyfte."}
          img={picTwo}
          align={"left"}
        />
      </div>
      <div>
      <h3 className="headerStaffpic">Hör av er till oss!</h3>
      </div>
      <div className="staffPic">
        <div>
          <img src={mattiasPic} alt="picOne" />
        </div>
        <div className="staffPicContactDetails">
          <h4>
            Mattias Ritzler
          </h4>
          <p className="mailMattias">mattias@kellstransport.se</p>
          <p>070 123 4567</p>
        </div>
      </div>
      <div>
        <SammarbetspartnerSection 
        headerTitle="Våra samarbetspartners"
        samarbetspartners={
          [
            {
              img: weebfleetImg
            },
            {
              img: BosImg
            },
            {
              img:ptvGroupImg
            },
            {
              img: budwheelsImg
            },
          ]} />
      </div>
      <div className="CtaSectionWithBg">
        <CtaSection
          title={"Hur mycket kan vi spara?"}
          text={"Måste minskade utsläppa belasta plånboken? Använd vår räknesnurra för att se hur mycket ruttplanering sänker er kostnader och utsläpp."}
          btnTitle={"Räknesnurra"}
          btnOnClick={handleClick2}
          bgImage={ctaBgImg}
        />
      </div>
    </div>
  );
}

