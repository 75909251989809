import React, { useEffect, useState } from "react";
import { OfficeAndMap } from "./OfficeAndMap";
import "./KontaktFormAndText.css";
import mattiasPic from "../assets/images/mattias2.png";
import { ContactFormEmailJs } from "./ContactFormEmailJs";

export interface IKontaktFormAndTextProps {
  title: string;
  text: string;
  linkTextBtn: string;
}

export const KontaktFormAndText: React.FC<IKontaktFormAndTextProps> = (
  props
) => {
  //create state that check if it is mobile or not
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 769;

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  if (width < breakpoint) {
    return (
      <div className="kontaktFormAndTextContainer">
        <div className="kontaktFormAndText">
          <div className="kontaktFormAndTextText">
            <h1>{props.title}</h1>
            <p className="contactText">{props.text}</p>
            <div className="contactFormFullForm">
            <ContactFormEmailJs />
          </div>
            <div className="specialistDiv">
              <h4 className="titleSpecialistDiv">Prata med vår specialist</h4>
              <div className="kontaktUppgifterMedBild">
                <img src={mattiasPic} alt="picOne" />
                <div className="staffPicContactDetailsKontaktPage">
                  <h4>Mattias Ritzler</h4>
                  <p className="mailMattias">mattias@kellstransport.se</p>
                  <p>070 123 4567</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="kontaktFormAndTextContainer">
        <div className="kontaktFormAndText">
          <div className="kontaktFormAndTextText">
            <h1>{props.title}</h1>
            <p className="contactText">{props.text}</p>
            <div className="specialistDiv">
              <h4>Prata med vår specialist</h4>
              <div className="kontaktUppgifterMedBild">
                <img src={mattiasPic} alt="picOne" />
                <div className="staffPicContactDetailsKontaktPage">
                  <h4>Mattias Ritzler</h4>
                  <p className="mailMattias">mattias@kellstransport.se</p>
                  <p>070 123 4567</p>
                </div>
              </div>
            </div>
          </div>
          <div className="contactFormFullForm">
            <ContactFormEmailJs />
          </div>
        </div>
      </div>
    );
  }
};
