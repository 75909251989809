import React from 'react';
import './App.css';
import { Navbar } from './components/Navbar';
import { NavbarFooter } from './components/NavbarFooter';
import { HomePage, OmOssPage, KontaktPage, VaraTjansterPage, BokaDemoPage, KalkylatorPage} from './pages';
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { ScrollToTop } from './components/ScrollToTop';


function App() {
  return (
    <BrowserRouter>
      <Navbar/>
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/om-oss" element={<OmOssPage />} />
        <Route path="/vara-tjanster" element={<VaraTjansterPage />} />
        <Route path="/kontakt" element={<KontaktPage />} />
        <Route path="/boka-demo" element={<BokaDemoPage />} />
        <Route path="/kalkylator" element={<KalkylatorPage />} />
      </Routes>
      <NavbarFooter/>
    </BrowserRouter>
  );
};

export default App;
